<template>
  <div class="advertise_box" @click="jumpLink(adv.jumpLink)">
    <ImgDecypt class="advertise" :src="adv.pictureUrl"/>
    <div class="text_box">
      <div class="title">{{ adv.name }}</div>
      <div class="content">{{ adv.desc }}</div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'advertise',
  props: {
    adv: {
      type: Object,
      required: true,
      default: () => {
        return {};
      }
    },
  },
  methods: {
    jumpLink(url){
      setTimeout(() => {
        window.open(url)
      })
    }
  }
}
</script>

<style lang="scss" scoped>
.advertise_box {
  width: 100%;
  height: 100%;
  position: relative;
  .advertise{
    width: 100%;
    height: 100%;
  }
  .text_box {
    position: absolute;
    bottom: 10px;
    left: 10px;
    color: white;
    .title {
      font-size: 26px;
      margin-bottom: 5px;
    }
    .content {
      font-size: 22px;
    }
  }
}
</style>