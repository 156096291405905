<template>
  <div class="short_video">
    <div class="my_tab">
      <div class="my_tab_b">
        <div :class="['tab_item',{'active': !isPageHot}]" @click="swipeRight">关注</div>
        <div :class="['tab_item',{'active': isPageHot}]" @click="swipeLeft">热门</div>
        <div class="b_line"></div>
      </div>
      <!-- <div class="search_box">
        <router-link class="search" to="/search">
          <svg-icon icon-class="search2"/>
        </router-link>
      </div> -->
    </div>
    <v-touch @swipeleft="swipeLeft" @swiperight="swipeRight" :swipe-options="{direction: 'horizontal'}" class="touch">
      <transition name="slide-l">
        <div class="video-s" v-show="isPageHot">
          <swiper class="video-swiper" :options="swiperOption" ref="mySwiper" v-show="storeVideoList.list.length >0">
            <swiper-slide class="my_swiper_silde" v-for="(item,index) in storeVideoList.list" :key="'item.id' + index">
              <Video @openCmt="openComment" :newUserTime="newUserTime" @openSelectLine="openSelectLine" @openShare="openShare" v-if="showVideo && !item.adv" :timeStr="timeStr" :videoShow="isPageHot && storeVideoList.index == index" :videoInfo="item" :index="index"/>
              <Advertise v-else-if="showVideo" :adv="item.adv"></Advertise>
            </swiper-slide>
          </swiper>
          <Loading v-if="!isHotNodata && storeVideoList.list.length === 0"/>
        </div>
      </transition>
      <!--  关注 -->
      <transition name="slide-r">
        <div class="video-r " v-show="!isPageHot">
          <swiper class="video-swiper" :options="swiperOptioncare" ref="mySwiperCare" v-if="videoObjCar.list.length > 0">
            <swiper-slide class="my_swiper_silde" v-for="(item,index) in videoObjCar.list" :key="'item.id' + index">
              <Video :newUserTime="newUserTime" :timeStr="timeStr" :videoShow="!isPageHot && videoObjCar.index==index" :videoInfo="item" :index="index"/>
            </swiper-slide>
          </swiper>
          <Loading v-if="!isCarNodata && videoObjCar.list.length === 0"/>
          <div class="l_nodata_box" v-if="videoObjCar.list.length==0 && isCarNodata ">
            <div class="l_nodata">
              <p class="nodata-top">暂无关注人动态</p>
              <p class="nodata-bottom">快去寻找更多可能认识的人来填充我吧</p>
            </div>
          </div>
        </div>
      </transition>
    </v-touch>
    <comment v-show="commentShow" :topicInfo="topicInfo" @commentSuccess="commentSuccess" :commentShow="commentShow" @commentClose="commentShow=false"/>
    <share v-if="shareShow" :topicInfo="topicInfo" @shareSuccess="shareSuccess" :shareShow="shareShow" @shareClose="shareShow=false"/>

    <div ref="adimg" class="advBox" v-if="floatAdvList.length > 0 && showAdv">
      <div @click="jumpPage" class="floatAdvBox">
        <ImgDecypt class="floatAdv" :src="floatAdvList[0].pictureUrl"  />
      </div>
      <div class="adClose" @click="closeAd" v-show="adCha">
        <svg-icon icon-class="close1"/>
      </div>
    </div>
     <!-- 切换路线框 -->
    <SelectionLinePopup
      v-if="showChangeRoadLine"
      @closeSelectLine="closeSelectLine"
      @close="showChangeRoadLine = false"
    />
  </div>
</template>
<script>
import {swiper, swiperSlide} from "vue-awesome-swiper";
import {Tab, Tabs} from "vant";
import Video from "@/views/short_video/video";
import Advertise from "@/views/short_video/advertise";
// import {careVideoList, queryVidList} from "@/api/video" copy
import { queryVidList } from "@/api/shortVideo"
import Loading from "@/components/Loading"
import {mapGetters} from "vuex";
import comment from '@/views/widget/comment.vue';
import share from '@/views/widget/share.vue';
import { getAdItem, AdType } from "@/utils/getAdv.js";
import { jumpAdv } from "@/utils/getAdv.js";
import { queryVipCards } from '@/api/mine/vip.js'
import SelectionLinePopup from "@/components/SelectionLinePopup";
// import {getSessionItem, removeSession} from "@/utils/longStorage";
export default {
  name: 'shortVideo',
  components: {
    Video,
    swiper,
    swiperSlide,
    Loading,
    Advertise,
    [Tab.name]: Tab,
    [Tabs.name]: Tabs,
    comment,
    share,
    SelectionLinePopup
  },
  computed: {
    ...mapGetters({
      storeVideoList: "videoObj",
      videoObjCar: "videoObjCar"
    })
  },
  data() {
    return {
      showVideo: true, // 视频是否显示
      showChangeRoadLine: false, // 显示切换路线
      commentShow:false,//评论是否显示

      isPageHot: true,  //是否是热门模块

      videoPage: 1, //短视频请求页码
      isHotNodata: false, // 热门没数据

      pageNumber: 1,  //关注数据页码
      isCarNodata: false, //关注没数据
      newUserTime: 0,
      videoSize: 10,  //一页短视频的条数
      topicInfo: {},
      shareShow: false,
      floatAdvList: [],
      showAdv: true,
      adCha: true,
      timeStr: '',
      swiperOption: {
        direction: "vertical",
        on: {
          slideChangeTransitionEnd: () => {
            if (!this.isPageHot) return;
            let activeIndex = this.$refs.mySwiper.swiper.activeIndex;
            this.$store.commit("video/UPDATE_VIDEOLIST_INDEX", {index: activeIndex, isFocus: false});
            let {list, index} = this.storeVideoList;
            //滑到倒数第二条数据加载下一页视频数据
            if (index === list.length - 2) {
              this.videoPage++;
              this.getVidList();
            }
            // 如果数据大于3页就删除1页数据
            if (index === 2 * this.videoSize && list.length === 3 * this.videoSize) {
              this.$refs.mySwiper.swiper.slideTo(
                  index - this.videoSize,
                  0,
                  true
              );
              //切换到第二页删除第一页数据
              this.$store.commit("video/UPDATE_VIDEOLIST_INDEX", {index: index - this.videoSize, isFocus: false});
              this.$store.commit("video/DELETE_VIDEOLIST", {index: this.videoSize, isFocus: false});
            }
          }
        },
      },
      swiperOptioncare: {
        direction: "vertical",
        on: {
          slideChangeTransitionEnd: () => {
            if (this.isPageHot) return;
            let {list, index} = this.videoObjCar;
            //切换页数
            let activeIndex = this.$refs.mySwiperCare.swiper.activeIndex;
            this.$store.commit("video/UPDATE_VIDEOLIST_INDEX", {index: activeIndex, isFocus: true});
            //滑到倒数第二条数据加载下一页视频数据
            if (index === list.length - 2) {
              this.pageNumber++;
              this.careVideos();
            }
            // 如果数据大于3页就删除1页数据
            if (index === 2 * this.videoSize && list.length === 3 * this.videoSize) {
              this.$refs.mySwiperCare.swiper.slideTo(
                  index - this.videoSize,
                  0,
                  true
              );
              //切换到第二页删除第一页数据
              this.$store.commit("video/UPDATE_VIDEOLIST_INDEX", {index: index - this.videoSize, isFocus: true});
              this.$store.commit("video/DELETE_VIDEOLIST", {index: this.videoSize, isFocus: true});
            }
          },
        },
      },
    }
  },
  activated() {
    // if (getSessionItem('followInfo')) {
    //   let followInfo = JSON.parse(getSessionItem('followInfo'))
    //   this.storeVideoList.list.forEach(i => {
    //     if (followInfo.uid == i.publisher.uid) {
    //       i.publisher.hasFollowed = followInfo.status;
    //     }
    //   })
    //   this.videoObjCar.list.forEach(i => {
    //     if (followInfo.uid == i.publisher.uid) {
    //       i.publisher.hasFollowed = followInfo.status;
    //     }
    //   })
    // }
  },
  deactivated() {
    // getSessionItem('followInfo') || removeSession('followInfo');
  },
  created() {
    this.getVip();
    this.$store.dispatch("user/getUserInfo"); //获取用户信息
    // this.$router.beforeEach((to, from, next) => {
    //   console.log(to);
    //   console.log(from);
    //   if(to.name === 'shortVideo' && from.name === 'vipList'){
    //     this.$router.go(0);
    //   }
    //   next();
    // });
    if (this.storeVideoList?.list.length > 0) {
      this.$nextTick(() => {
        this.$refs.mySwiper.swiper.slideTo(this.storeVideoList.index, 0, true);
      })
    } else {
      this.getVidList();
    }
    this.$store.dispatch("user/setWallet");
    this.floatAdvList = getAdItem(AdType.floatAd);
  },
  mounted() {
  },
  methods: {
    openSelectLine() {
      this.showChangeRoadLine = true;
    //   console.log('showChang')
    },
    // 关闭线路选择
    closeSelectLine() {
      this.showVideo = false;
      this.showChangeRoadLine = false;
      this.$nextTick(() => {
        this.showVideo = true;
      });
    },
    // 获取vip
    async getVip(){
      let ret = await this.$Api(queryVipCards);
      if (ret && ret.code == 200) {
        let vipCardList = ret.data.list.filter(i => {
          return i.cardType == 1;
        });
        let newUserArr = vipCardList.filter((i) => {
          return i.newUserLeftTime;
        });
        this.newUserTime = newUserArr[0] ? newUserArr[0].newUserLeftTime : 0;
        this.countdown();
      }
    },
    // 倒计时
    countdown() {
      let msec = this.newUserTime;
      if(msec <= 0) return;
      // 计算时分秒数
      let hr = parseInt((msec / 60 / 60) % 24);
      let min = parseInt((msec / 60) % 60);
      let sec = parseInt(msec % 60);
      // 个位数前补零
      hr = hr > 9 ? hr : "0" + hr;
      min = min > 9 ? min : "0" + min;
      sec = sec > 9 ? sec : "0" + sec;
      this.timeStr = `新人限时年卡 ${hr}:${min}:${sec}`;
      // 一秒后递归
      setTimeout(() => {
        this.newUserTime--;
        this.countdown();
      }, 1000);
    },
    closeAd() {
      let dom = this.$refs.adimg;
      this.adCha = false;
      console.log(dom)
      dom.style.width = ((window.innerWidth > 600 ? 600 : window.innerWidth) / 375) * 17 + "px";
      dom.style.borderRadius = ((window.innerWidth > 600 ? 600 : window.innerWidth) / 375) * 17 + "px";
      dom.style.overflow = "hidden";
      // this.showAdv = false;
      // console.log(123123)
    },
    jumpPage() {
      if (!this.adCha) {
        let dom = this.$refs.adimg;
        dom.style.width =
          ((window.innerWidth > 600 ? 600 : window.innerWidth) / 375) * 80 +
          "px";
        dom.style.borderRadius = "0px";
        dom.style.overflow = "inherit";
        this.adCha = true;
      } else {
        jumpAdv(this.floatAdvList[0]);
      }
    },
    swipeLeft() {
      // return
      if (this.isPageHot) {
        let index = this.storeVideoList.index;
        if(!this.storeVideoList.list[index].author) return;
        let uid = this.storeVideoList.list[index].author.id;
        this.$router.push({path: '/bloggerDetails', query: {id: uid}})
      }

      this.isPageHot = true;
      if (this.storeVideoList.list.length == 0 && !this.isHotNodata) {
        this.getVidList();
      } else {
        this.$nextTick(() => {
          this.$refs.mySwiper?.swiper.slideTo(this.storeVideoList.index, 0, true);
        })
      }

    },
    swipeRight() {
      this.isPageHot = false;
      this.isCarNodata = false;
      if (this.videoObjCar.list.length == 0 && !this.isCarNodata) {
        //如果关注的人没有数据才重新拉去数据
        this.careVideos();
      } else {
        this.$nextTick(() => {
          this.$refs.mySwiperCare.swiper.slideTo(this.videoObjCar.index, 0, true);
        })
      }
    },

    openComment(data){
      this.commentShow = true;
      this.topicInfo = data;
    },

    openShare(data){
      this.shareShow = true;
      this.topicInfo = data;
    },

    commentSuccess(){//评论成功评论条数加一
      let index = this.storeVideoList.index;
      this.storeVideoList.list[index].commentNum++;
      localStorage.setItem('videoList',JSON.stringify(this.storeVideoList));
    },
    shareSuccess(){//分享成功回调
      let index = this.storeVideoList.index;
      this.storeVideoList.list[index].shareNum ++;
      localStorage.setItem('videoList',JSON.stringify(this.storeVideoList));
      // let videoList = JSON.parse(localStorage.getItem('videoList'));//更改存的视频列表的状态
      // videoList.list[videoList.index] = this.vidList[this.pageNum];
      // localStorage.setItem('videoList',JSON.stringify(videoList));//更改存储local里面的购买状态
    },

    //获取视频推荐列表
    async getVidList() {
      let req = {
        id: 'post_video',
        pageNum: this.videoPage,
        pageSize: this.videoSize,
        tags: []
      };
      let ret = await this.$Api(queryVidList, req);
      if (ret && ret.code === 200) {
        if (ret.data.mediaInfos !== null) {
          let vidList = this.storeVideoList?.list?.concat(ret.data.mediaInfos);

          this.$store.commit("video/SET_VIDEOLIST", {
            isFocus: false,
            list: vidList
          });
        }
        if (this.storeVideoList?.length == 0 && this.videoPage == 1) {
          this.isHotNodata = true;
        }
      }
    },
    //  获取关注数据
    async careVideos() {
      //请求关注的人的视频列表
      let req = {
        id: 'post_video',
        pageNum: this.pageNumber,
        pageSize: this.videoSize,
        tags: [],
        focus: true
      };
      let ret = await this.$Api(queryVidList, req);
      if (ret && ret.code === 200) {
        if (ret.data.list !== null) {
          let careList = this.videoObjCar?.list?.concat(ret.data.mediaInfos);
          this.$store.commit("video/SET_VIDEOLIST", {
            list: careList,
            isFocus: true,
          });
        }
        if (this.videoObjCar?.list?.length == 0 && this.pageNumber == 1) {
          this.isCarNodata = true;
        }
      }
    },
  },
}
</script>
<style lang="scss" scoped>
.short_video {
  height: 100%;
  width: 100%;
  background: #000000;
  position: relative;

  .bottom-all {
    height: 100%;
    position: relative;
  }

  .my_tab {
    position: absolute;
    top: 0;
    height: 50px;
    z-index: 10;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    color: #abaaac;

    .search_box {
      position: absolute;
      right: 16px;

      .search {
        > svg {
          font-size: 18px;
        }
      }
    }

    .my_tab_b {
      display: flex;
      align-items: center;
      justify-content: center;
      position: relative;
      width: 160px;

      .tab_item {
        padding: 0 18px;
        font-size: 18px;
        position: relative;
      }

      .active {
        color: #ffffff;
      }

      .tab_item:nth-child(2).active ~ .b_line {
        left: 105px;

      }

      .b_line {
        content: "";
        display: inline-block;
        position: absolute;
        bottom: -6px;
        left: 32px;
        width: 20px;
        height: 4px;
        background: #ff7f0f;
        border-radius: 5px;
        transition: all 0.1s linear;
      }
    }

  }

  .video-s {
    width: 100%;
    height: 100%;
    position: absolute;
    overflow: hidden;

    .video-swiper {
      height: 100%;
      width: 100%;

      .my_swiper_silde {
        width: 100%;
        height: 100%;
      }
    }
  }

  .touch {
    height: 100%;

    .video-r {
      height: 100%;

      .l_nodata_box {
        height: 100%;
        display: flex;
        align-items: center;
        justify-content: center;

        .l_nodata {
          text-align: center;

          .nodata-top {
            font-size: 18px;
          }

          .nodata-bottom {
            margin-top: 15px;
            font-size: 14px;
            color: #919191;
          }
        }

      }

      .video-swiper {
        height: 100%;
        width: 100%;

        .my_swiper_silde {
          width: 100%;
          height: 100%;
        }
      }
    }
  }

}

.advBox {
  position: fixed;
  top: 3%;
  left: 3%;
  width: 100px;
  height: 100px;
  z-index: 11;
  border-radius: 6px;
  
  .floatAdvBox{
    width: 100%;
    height: 100%;
    border-radius: 6px;
  }
  .floatAdv {
    width: 100%;
    height: 100%;
    border-radius: 6px;
  }
  /deep/ .vanImage {
    border-radius: 6px;
  }
  /deep/ img {
    border-radius: 6px;
  }
}

</style>
